<template>
    <div>
        <DoctorConfirm
            :isOver="isOver"
            :patientInfo="patientInfo"
            :fromOrigin="fromOrigin"
            @allOver="allOver"
            @closeAgree="closeAgree"
            v-if="fromOrigin === 'ZHONGAN'"
        />
        <DoctorConfirmForOther
            :isOver="isOver"
            :patientInfo="patientInfo"
            :fromOrigin="fromOrigin"
            @allOver="allOver"
            @closeAgree="closeAgree"
            v-else
        />
    </div>
</template>

<script>
import DoctorConfirm from './doctorConfirm';
import DoctorConfirmForOther from './doctorConfirmForOther';
export default {
    components: {
        DoctorConfirm,
        DoctorConfirmForOther,
    },
    props: {
        patientInfo: {
            type: Object,
            required: true,
        },
        isOver: {
            type: Boolean,
            required: true,
        },
        fromOrigin: {
            type: String,
            required: true,
        },
    },
    methods: {
        allOver() {
            this.$emit('allOver', true);
        },
        closeAgree() {
            this.$emit('closeAgree', true);
        },
    },
};
</script>

<style></style>
